<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="navigation_bar_color"
      app
      temporary
      dark
    >
      <v-list>
        <v-list-item>
          <img
            src="@/assets/icons/nav_bar/logo.png"
            alt="Logo"
            transition="scale-transition"
            height="40"
          />
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card flat class="mx-auto pt-6" width="300" dark color="transparent">
        <v-list flat color="transparent">
          <div v-for="(link, i) in links" :key="i">
            <v-list-item :to="link.route" v-if="link.sub_links.length < 1">
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
            <v-list-group v-else color="white">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ link.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                style="margin-left: 20px !important"
                v-for="sub in link.sub_links"
                :key="sub.name"
                link
                :href="sub.route"
                :target="sub.target"
              >
                <v-list-item-title
                  v-text="sub.name"
                  :class="sub.bold ? 'font-weight-bold' : ''"
                ></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-card>
    </v-navigation-drawer>

    <v-app-bar
      style="z-index: 100; padding: 0.75rem; height: auto"
      app
      color="navigation_bar_color"
      :flat="flat"
      dark
      :class="{ expand: flat }"
      class="content-nav-padding-0"
    >
      <v-row style="display: flex; justify-content: center">
        <v-col cols="4" lg="2" md="1" xs="12">
          <div
            style="height: 100% !important"
            class="d-flex align-items-center"
          >
            <v-img
              alt="Columbia Logo"
              class="shrink"
              contain
              src="@/assets/icons/nav_bar/logo.png"
              transition="scale-transition"
              width="130"
            />
          </div>
        </v-col>

        <v-col
          lg="8"
          md="9"
          xs="12"
          cols="6"
          class="d-flex justify-end align-center pa-0"
        >
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            class="mr-4"
            v-if="isXs"
          />

          <div style="width: 100%" class="d-flex justify-end" v-else>
            <div class="text-center d-flex" style="gap: 1rem">
              <v-menu
                offset-y
                v-for="(link, i) in links"
                transition="slide-x-transition"
                :key="i"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="btn"
                    style="font-size: 0.8rem; padding: 0"
                    small
                    :to="link.route"
                    depressed
                    dark
                    v-bind="attrs"
                    v-on="on"
                    color="transparent"
                  >
                    {{ link.name }}
                    <v-icon v-if="link.list">{{ link.icon }}</v-icon>
                  </v-btn>
                </template>
                <v-list v-if="link.sub_links.length">
                  <v-list-item v-for="(sub, i) in link.sub_links" :key="i" :href="sub.route" :target="sub.target">
                    <v-list-item-title :style="{ 'font-size': font }" :class="sub.bold ? 'font-weight-bold' : ''">
                      {{ sub.name }} 
                      <br> {{sub.subname}}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    font: "",
    drawer: false,
    isXs: false,
    items2: [
      ["mdi-home-outline", "Home", "/home"],
      ["mdi-home-outline", "Home", "/abou1"],
    ],
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    links: [
      {
        name: "Inicio",
        route: "/home",
        list: false,
        icon: "",
        sub_links: [],
      },
      {
        name: "Sobre Columbia",
        route: "",
        list: true,
        icon: "mdi-chevron-down",
        sub_links: [
          {
            name: "¿Por qué Columbia?",
            route: "/about1",
            target: "_parent",
          },
          {
            name: "Nuestra Historia",
            route: "/about2",
            target: "_parent",
          },
          {
            name: "Nuestras Sedes",
            route: "/about3",
            target: "_parent",
          },
        ],
      },
      {
        name: "Oferta Educativa",
        route: "",
        list: true,
        icon: "mdi-chevron-down",
        sub_links: [
          {
            name: "INSTITUTO COLUMBIA",
            route: "/educative_offer",
            bold: true,
            target: "_self",
          },
          {
            name: "Administración de Servicios de Hostelería y Restaurantes",
            subname: "Con mención en Gerencia de la Hospitalidad Internacional",
            route: "/hoteleriainternacional",
            bold: false,
            target: "_self",
          },
          {
            name: "Cocina",
            route: "/altacocina",
            bold: false,
            target: "_self",
          },
          {
            name: "PROGRAMAS DE FORMACIÓN CONTINUA",
            route: "/columbiaonline",
            bold: true,
            target: "_self",
          },
          {
            name: "Panadería y Pastelería",
            route: "/pasteleriapanaderia",
            bold: false,
            target: "_self",
          }, 
          {
            name: "Bartender",
            route: "/bartender",
            bold: false,
            target: "_self",
          },
          {
            name: "Software Informático para Hoteles y Restaurantes: Inforest - Infohotel",
            route: "/columbiaonline#software-informatico-para-hoteles-y-restaurantes",
            bold: false,
            target: "_self",
          },            
          //{
          //  name: "Programas de Formación Continua Online",
          //  route: "/columbiaonline",
          //  bold: true,
          //  target: "_self",
          //},
          {
            name: "Counter",
            route: "/columbiaonline#counter",
            bold: false,
            target: "_self",
          },
          {
            name: "Servicio y Atención de Viajeros a Bordo",
            route: "/columbiaonline#atencion",
            bold: false,
            target: "_self",
          },
          {
            name: "Servicio y Hospitalidad en Vuelos Comerciales",
            route: "/columbiaonline#cabin-crew",
            bold: false,
            target: "_self",
          },
          {
            name: "CETPRO COLUMBIA",
            route: "/educative_offer7",
            bold: true,
            target: "_self",
          },
          {
            name: "Aviación Comercial",
            route: "/aviacioncomercial",
            bold: false,
            target: "_self",
          },
        ],
      },
      {
        name: "Partners",
        route: "/partners",
        list: false,
        icon: "",
        sub_links: [],
      },
      {
        name: "Profesionales Columbia",
        route: "/professionals",
        list: false,
        icon: "",
        sub_links: [],
      },
      {
        name: "Portal del alumno",
        route: "",
        list: true,
        icon: "mdi-chevron-down",
        sub_links: [
          {
            name: "ColumbiaNET",
            route: "https://columbianet.columbia.edu.pe",
            target: "_blank",
          },
          {
            name: "Correo Institucional",
            route: "https://mail.office365.com",
            target: "_blank",
          },
          {
            name: "Bolsa de Trabajo",
            route: "https://empleabilidad.columbia.edu.pe/",
            target: "_blank",
          },
          {
            name: "Biblioteca Virtual",
            route: "https://columbianet.columbia.edu.pe",
            target: "_blank",
          },
        ],
      },
      {
        name: "Portal del docente",
        route: "",
        list: true,
        icon: "mdi-chevron-down",
        sub_links: [
          {
            name: "ColumbiaNET",
            route: "https://columbianet.columbia.edu.pe/",
            target: "_blank",
          },
          {
            name: "Correo Institucional",
            route: "https://mail.office365.com",
            target: "_blank",
          },
          {
            name: "Bolsa de Trabajo",
            route: "https://empleabilidad.columbia.edu.pe/",
            target: "_blank",
          },
        ],
      },
    ],
    items: {
      title: "Manutenção",
      icon: "mdi-clipboard-outline",
      to: "",
      items: [
        {
          title: "Convênios",
          icon: "mdi-clipboard-outline",
          to: "/convenios",
        },
        {
          title: "Planos",
          icon: "mdi-format-font",
          to: "",
          items: [
            {
              title: "Test1",
              icon: "mdi-chart-bubble",
              to: "/test1",
            },
            {
              title: "Test2",
              icon: "mdi-chart-bubble",
              to: "/test2",
            },
          ],
        },
      ],
    },
  }),
  props: {
    color: String,
    flat: Boolean,
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 1000;
      if (window.innerWidth > 1650) {
        this.font = "18px";
      } else if (window.innerWidth <= 1200) {
        this.font = "10px !important";
      } else {
        this.font = "";
      }
    },
  },
};
</script>

<style scope>
.v-btn__content {
  text-transform: initial;
  font-weight: 300;
}

.theme--dark.v-btn:hover::before {
  opacity: 0.5;
}

.nav_h {
  height: auto;
}
.content-nav-padding-0 > div {
  padding: 0;
}

/* .v-toolbar__content {
  height: 100px !important;
} */

.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.navigation_bar_color {
  background: rgb(0, 113, 255) !important;
  background: linear-gradient(
    90deg,
    rgba(0, 113, 255, 1) 1%,
    rgba(96, 53, 195, 1) 100%
  ) !important;
}

.icon_flex {
  display: initial !important;
}

.v-item-group .v-expansion-panels {
  position: initial !important;
}

#btn::before {
  color: transparent !important;
  border-bottom: 2px solid;
  border-bottom-color: currentcolor;
  border-color: white;
  height: 35px;
  border-radius: 0px !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 1;
}
</style>
