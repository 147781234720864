<template>
  <svg v-if="name === 'book'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.86 28.77">
    <path xmlns="http://www.w3.org/2000/svg"
      d="m34.6,27.34c-2.79,0-5.58,0-8.38,0-.67,0-1.25.14-1.78.59-1.32,1.11-3.62,1.15-5.01,0-.53-.44-1.1-.59-1.77-.59-5.66.01-11.31,0-16.97,0q-.76,0-.61-.72c1.26-6.32,2.52-12.64,3.78-18.96.07-.37.14-.74.17-1.12.07-.81.51-1.4,1.22-1.75.49-.25,1.01-.48,1.54-.62.83-.21,1.18-.74,1.25-1.54.07-.74.23-1.47.36-2.2.05-.27.17-.41.46-.41,2.69-.04,5.38,0,7.99.81,1.94.6,3.65,1.57,4.9,3.22.28.36.54.39.94.26,1.61-.53,3.21-1.08,4.9-1.32,3.71-.51,7.25.03,10.62,1.68,1.5.74,1.26.39,1.6,2.07.76,3.74,1.5,7.48,2.25,11.22.59,2.92,1.17,5.85,1.75,8.77.11.56.06.63-.54.63-2.89,0-5.79,0-8.68,0Zm-12.13-11.94s0,0,0,0c0,3,0,5.99,0,8.99,0,.61.07.65.63.47.14-.04.28-.09.42-.14,2.45-.79,4.95-1.25,7.52-1.35,3.36-.14,6.64.35,9.85,1.35.79.24.79.23.62-.61-.51-2.56-1.03-5.13-1.54-7.69-.65-3.27-1.3-6.53-1.94-9.8-.1-.49-.26-.91-.77-1.13-4.64-1.97-9.3-2.03-13.98-.11-.6.25-.83.62-.83,1.27.02,2.92.01,5.84.01,8.77Zm-1.08-8.13c.18-1.3-.3-2.46-1.38-3.39-.91-.79-1.92-1.38-3.04-1.79-2.2-.79-4.48-.91-6.78-.91-.73,0-.72,0-.85.73-1.07,6.04-2.15,12.08-3.22,18.13-.11.61-.11.64.5.6,2.11-.15,4.2-.14,6.29.18,2.84.44,5.51,1.31,7.82,3.1.17.13.35.43.57.31.2-.11.09-.43.09-.66,0-5.39,0-10.78,0-16.3Zm-2.34,16.96c-.1-.09-.13-.12-.16-.15-.11-.07-.22-.14-.34-.21-2.43-1.36-5.07-2.01-7.83-2.15-1.88-.1-3.77-.03-5.64.2-.36.04-.46-.06-.37-.4.02-.08.02-.17.04-.26.77-4.34,1.54-8.69,2.31-13.03.16-.91.33-1.81.47-2.72.02-.11.15-.32-.11-.34-.59-.03-1.39.63-1.51,1.24-1.18,5.93-2.36,11.86-3.54,17.79-.15.76-.14.76.59.52,4.23-1.36,8.54-1.72,12.94-1.11,1.03.14,2.05.37,3.16.61Z" />
  </svg>
  <svg v-else-if="name === 'pay'" xmlns="http://www.w3.org/2000/svg" id="Capa_2" data-name="Capa 2"
    viewBox="0 0 136.5 35.78">
    <g id="Capa_1-2" data-name="Capa 1">
      <g>
        <path class="cls-23"
          d="m6.61.53c2.31.22,4.92,1.09,7.45,2.19,6.08,2.64,11.72,5.99,16.68,10.42,1.25,1.12,2.42,2.32,3.16,3.87.36.75.35,1.45-.1,2.18-1.37,2.18-3.35,3.73-5.38,5.21-5.39,3.95-11.19,7.2-17.46,9.57-1.7.64-3.46,1.08-5.27,1.28-1.19.13-2.08-.38-2.64-1.48-.99-1.95-1.45-4.05-1.76-6.2-.52-3.56-.86-7.14-.75-10.74.13-4.18.36-8.36,1.59-12.41.21-.7.5-1.37.8-2.03.64-1.4,1.74-1.92,3.66-1.84Z" />
        <g>
          <path class="cls-2t"
            d="m62.87,14.73c3.46.13,3.48.13,2.92,3.43-.39,2.3-.78,4.61-1.26,6.89-.44,2.08-1.45,2.85-3.58,2.97-1.24.07-2.42-.12-3.61-.49-.77-.24-.69-.75-.57-1.32.14-.7.64-.37,1-.31,1.03.19,2.07.33,3.12.24.43-.04.82-.14,1.1-.51.2-.26.36-.58.24-.87-.13-.33-.49-.1-.74-.11-.52-.02-1.05.03-1.57-.03-1.75-.2-2.74-1.32-2.77-3.09-.02-1.2.22-2.36.54-3.5.69-2.5,2.18-3.43,5.18-3.31Zm-1.52,8.08c.39-.24,1.21.37,1.39-.55.33-1.68.62-3.36.91-5.04.03-.19.1-.39-.16-.53-1.05-.56-2.95.11-3.39,1.24-.39.98-.52,2.03-.61,3.07-.13,1.5.25,1.86,1.87,1.82Z" />
          <path class="cls-2t"
            d="m43.45,11.57c.98.02,1.96,0,2.91.3,1.53.49,2.26,1.52,2.22,3.13-.06,2.36-1.61,4.19-3.93,4.62-.41.08-.83.1-1.24.18-.74.14-1.69-.38-2.17.21-.41.5-.33,1.39-.47,2.11-.11.55-.26,1.09-.31,1.64-.06.66-.35.87-1.01.87-1.62,0-1.62.03-1.33-1.58.61-3.36,1.24-6.71,1.79-10.08.13-.8.42-1.19,1.23-1.22.77-.03,1.53-.11,2.29-.17Zm-.31,6.22c.19-.02.65-.02,1.09-.12,1.25-.3,2.05-1.55,1.88-2.88-.11-.85-.72-1.2-2.21-1.22-.21,0-.42.03-.63,0-.84-.09-1.18.29-1.22,1.12-.03.59-.19,1.17-.3,1.75q-.26,1.35,1.39,1.35Z" />
          <path class="cls-2t"
            d="m81.9,18.96c-.1,0-.21,0-.31,0-2.45,0-2.56.13-2.63,2.58-.02.79.33,1.1,1.04,1.11,1.01.02,2.03-.01,3.04.02.33.01.94-.22.88.38-.05.48.19,1.27-.59,1.4-1.72.29-3.48.51-5.19-.04-1.12-.36-1.76-1.21-1.63-2.4.3-2.74.73-5.47,1.47-8.12.36-1.29,1.36-2.04,2.68-2.2,1.49-.18,3-.17,4.5.06.34.05.89.02.7.63-.16.5.08,1.3-.87,1.29-.94,0-1.89,0-2.83,0-1.63,0-1.85.19-2.14,1.78q-.29,1.55,1.29,1.55c.84,0,1.68-.02,2.52.01.36.01.99-.23.88.5-.08.58.15,1.45-.92,1.46-.63,0-1.26,0-1.89,0Z" />
          <path class="cls-2t"
            d="m96.93,20.49c-.63,0-1.26-.05-1.88.02-.4.04-1.06-.38-1.15.45-.09.76.1,1.41.92,1.65,1.08.32,2.14.04,3.2-.14.4-.07,1.02-.56,1.14,0,.13.59.11,1.41-.8,1.7-1.24.4-2.5.62-3.81.48-1.75-.19-2.89-1.34-2.96-3.08-.05-1.55.24-3.07.89-4.47.96-2.07,3.95-3.09,6.03-2.1,1.24.59,1.69,1.69,1.77,2.98.14,2.23-.11,2.5-2.32,2.5-.35,0-.7,0-1.05,0Zm-.88-1.63c.35,0,.7-.04,1.05,0,.88.11,1.01-.33.86-1.06-.13-.63-.49-1-1.1-1.1-.81-.13-1.54.09-2.05.77-.27.36-.61.81-.43,1.22.16.39.69.11,1.05.16.2.03.42,0,.63,0Z" />
          <path class="cls-2t"
            d="m71.88,14.71c2.49,0,3.75,1.32,3.59,3.79-.07,1.01-.27,2-.59,2.96-.85,2.5-3.15,3.76-5.72,3.14-1.47-.35-2.5-1.68-2.44-3.36.05-1.36.29-2.71.86-3.96.84-1.83,2.35-2.57,4.3-2.58Zm1.26,3.85c.02-1.51-.74-2.15-1.99-1.83-.64.16-1.15.52-1.41,1.14-.46,1.11-.7,2.27-.67,3.46.02.98.58,1.43,1.57,1.39.94-.04,1.56-.53,1.9-1.39.37-.95.48-1.95.6-2.78Z" />
          <path class="cls-2t"
            d="m51.82,24.73c-.42-.02-.84-.03-1.25-.06-1.71-.14-2.63-.87-2.74-2.19-.15-1.78.8-3.14,2.44-3.52.9-.21,1.81-.27,2.71-.18.85.09.87-.42.92-1,.06-.67-.16-1.08-.89-1.13-.88-.06-1.74.08-2.6.25-.32.06-.84.46-.95-.05-.11-.53-.13-1.35.49-1.58,1.66-.62,3.4-.91,5.11-.2,1.04.43,1.35,1.42,1.21,2.45-.29,2.04-.69,4.06-1.04,6.09-.08.48-.33.7-.81.8-.86.18-1.72.28-2.6.31Zm-.09-1.75c1.27,0,1.7-.63,1.67-2.23-.01-.78-.59-.44-.91-.44-1.7-.03-2.28.39-2.28,1.58,0,1.18.89,1.02,1.52,1.09Z" />
          <path class="cls-2t"
            d="m132.93,14.71c2.49,0,3.79,1.42,3.54,3.94-.09.86-.27,1.72-.51,2.56-.69,2.42-2.39,3.6-4.91,3.49-2.18-.1-3.55-1.63-3.32-3.81.1-.93.29-1.86.56-2.76.68-2.3,2.24-3.42,4.65-3.42Zm1.22,4.09c.08-1.73-.79-2.42-2.11-2.03-.65.19-1.12.6-1.35,1.21-.39,1.01-.6,2.08-.64,3.16-.05,1.14.69,1.73,1.82,1.57.89-.12,1.47-.65,1.71-1.48.26-.9.43-1.83.57-2.44Z" />
          <path class="cls-2t"
            d="m90.4,14.76c.28.01.56-.01.83.05.39.09,1.08-.35,1.03.56-.04.74-.2,1.33-1.15,1.17-.8-.13-1.14.13-1.27,1.02-.32,2.28-.8,4.53-1.19,6.8-.44,2.55-1.61,3.41-4.16,3.05-.42-.06-.78-.09-.67-.66.09-.45-.04-1.13.71-1.03,1.39.19,1.7-.58,1.87-1.74.31-2.14.73-4.26,1.15-6.38.14-.72.16-1.22-.81-1.21-.35,0-.9.03-.8-.67.08-.59.27-1.04.97-.91.88.17,1.19-.22,1.33-1.06.4-2.33,1.86-2.77,4.27-2.46.47.06.51.36.41.77-.1.44-.06.86-.74.9-1.57.08-1.56.13-1.77,1.81Z" />
          <path class="cls-2t"
            d="m121.2,14.77c.56-.12.85.17.87,1.03.05,2.02.2,4.04.34,6.07.02.3-.22.8.28.86.37.05.41-.42.53-.69.87-2.09,1.71-4.19,2.57-6.28.33-.81,1.73-1.31,2.5-.92.33.17.2.4.1.61-1.24,2.69-2.44,5.39-3.74,8.05-.52,1.05-1.87,1.48-3,1.13-.68-.21-1.04-.69-1.11-1.38-.28-2.53-.52-5.07-.81-7.6-.13-1.13.66-.79,1.47-.88Z" />
          <path class="cls-2t"
            d="m112.3,24.7c-2.04.05-2.74-.72-2.43-2.75.24-1.55.52-3.09.83-4.63.13-.62.02-.87-.67-.92-.42-.03-1.12.15-1.05-.76.07-.87.61-.93,1.24-.84.78.11,1.04-.23,1.11-.96.06-.67,1.88-1.42,2.35-.99.33.3.02.65-.04.97-.12.61-.18,1.09.73.98.37-.04,1-.27.95.54-.04.66-.11,1.34-1.04,1.17-.93-.17-1.18.29-1.3,1.11-.21,1.48-.51,2.95-.79,4.42-.1.53-.03.86.64.9.38.02,1.09-.41,1.1.44,0,.75-.23,1.39-1.21,1.27-.14-.02-.28.02-.42.03Z" />
          <path class="cls-2t"
            d="m106.01,14.71c.56.02,1.1.11,1.65.26.89.24,1.06.73.76,1.55-.15.41-.26.53-.72.45-3.25-.57-3.59-.31-4.18,2.98-.47,2.62.15,3.2,2.72,2.62.33-.07.88-.51,1-.06.15.55.13,1.38-.49,1.68-1.33.64-2.77.83-4.13.12-1.29-.67-1.73-1.86-1.6-3.26.1-1.08.29-2.14.62-3.17.69-2.15,2.1-3.17,4.37-3.16Z" />
          <path class="cls-2t"
            d="m115.97,24.57c-1,.23-1.26-.15-1.08-1.1.51-2.6.96-5.21,1.43-7.81.16-.85.7-1.1,1.93-.85.82.16.39.78.33,1.16-.41,2.51-.92,5-1.32,7.52-.13.85-.39,1.34-1.3,1.09Z" />
          <path class="cls-2t"
            d="m117.8,13.52c-.29-.2-1.06.42-1.06-.56,0-1.37.28-1.72,1.56-1.68.4.01,1.06-.33,1.08.52.04,1.31-.33,1.72-1.58,1.72Z" />
        </g>
        <path class="cls-33"
          d="m14.05,21.4c1.87-1.6,3.7-3.15,5.52-4.7.26-.22.15-.42-.02-.62-1.29-1.55-2.59-3.1-3.87-4.64-.12.08-.21.11-.22.15q-.35,1.74-2.1,1.74c-1.36,0-2.73-.04-4.09.02-.77.03-.9-.26-.71-.91.12-.4.21-.81.27-1.22.1-.62.5-.94,1.06-.99,3.16-.31,6.33-.64,9.5-.06.9.17,1.75.48,2.53.95,1.31.78,2.01,1.94,2.01,3.45,0,2.07-.51,4.01-1.74,5.71-.96,1.32-2.24,2.16-3.85,2.53-2.2.5-4.42.69-6.66.51-.67-.05-.92.2-1.05.81-.34,1.57-.76,3.12-1.07,4.69-.15.77-.55,1.06-1.29,1.04-.8-.02-1.61,0-2.41-.01-.59,0-.81-.3-.69-.88.63-3.04,1.26-6.08,1.87-9.12.11-.55.41-.63.89-.62,1.82.02,3.63.04,5.45,0,.71-.02.85.22.67.86-.11.38-.22.79,0,1.34Z" />
      </g>
    </g>
  </svg>


  <svg v-else-if="name === 'bag'" xmlns="http://www.w3.org/2000/svg" id="Capa_2" data-name="Capa 2">

    <path class="cls-2t"
      d="m56.78,78.4c-5.85,0-11.71,0-17.56,0-2.08,0-3.98-.61-5.62-1.91-2.06-1.64-3.19-3.78-3.4-6.41-.04-.51-.04-1.01-.04-1.52,0-7.13,0-14.27,0-21.4,0-2.56.88-4.78,2.72-6.57,1.63-1.58,3.6-2.46,5.88-2.49,2.81-.04,5.62-.01,8.43-.01q.48,0,.49-.5c.02-3.41,2.65-6.02,6.06-6.03,2.01,0,4.01,0,6.02,0,2.34,0,4.14.98,5.37,2.99.55.91.77,1.92.8,2.97.02.57,0,.57.59.57,2.63,0,5.27,0,7.9,0,2.59-.01,4.79.91,6.57,2.77,1.46,1.52,2.26,3.37,2.42,5.48.05.57.04,1.15.04,1.72,0,7.07,0,14.13,0,21.2,0,2.47-.81,4.63-2.53,6.41-1.58,1.62-3.5,2.52-5.77,2.69-.55.04-1.1.04-1.65.04-5.58,0-11.16,0-16.74,0Zm.01-2.65c5.92,0,11.84,0,17.76,0,3.47,0,6.26-2.81,6.27-6.28,0-3.96,0-7.92,0-11.87,0-.42-.01-.43-.4-.32-3.2.95-6.46,1.7-9.75,2.26-1.88.32-3.77.57-5.67.76-1.25.12-2.5.21-3.76.29-.57.04-.57.06-.58.63-.06,2.32-2.22,4.08-4.5,3.67-1.88-.34-3.18-1.89-3.19-3.83,0-.32-.08-.45-.42-.46-.8-.03-1.6-.1-2.41-.16-2.3-.17-4.58-.46-6.85-.83-3.42-.57-6.79-1.33-10.11-2.32-.35-.11-.38-.09-.38.28,0,4.02,0,8.05,0,12.07,0,1.03.26,2.01.79,2.9,1.25,2.1,3.1,3.21,5.56,3.22,5.88.01,11.75,0,17.63,0Zm0-35.04c-5.81,0-11.62,0-17.43,0-.34,0-.68.02-1.02.05-3.07.34-5.51,3.03-5.53,6.11-.01,2.38,0,4.76,0,7.14,0,.3.1.43.38.51,2.55.78,5.14,1.43,7.75,1.96,2.62.53,5.25.92,7.91,1.18,1.25.12,2.5.19,3.76.29.27.02.37-.07.37-.35,0-.44.08-.88.24-1.29.68-1.7,2.38-2.7,4.14-2.44,1.78.26,3.12,1.68,3.3,3.5q.06.6.67.58s.09,0,.13,0c2.55-.17,5.1-.41,7.62-.81,3.85-.61,7.64-1.46,11.36-2.6.27-.08.39-.2.39-.5-.01-2.39,0-4.78,0-7.18,0-.87-.19-1.71-.58-2.49-1.2-2.37-3.13-3.63-5.79-3.65-5.89-.03-11.77,0-17.66,0Zm.01-2.62c2.04,0,4.08,0,6.12,0,.26,0,.37-.06.38-.35.04-2.06-1.37-3.53-3.41-3.53-2.04,0-4.08,0-6.12,0-2.05,0-3.44,1.41-3.44,3.47,0,.32.1.41.41.41,2.02-.01,4.04,0,6.05,0Zm1.21,21.32c0-.58,0-1.17,0-1.75-.01-.66-.53-1.19-1.16-1.2-.66,0-1.18.47-1.22,1.14-.06,1.17-.04,2.33,0,3.5.02.7.53,1.21,1.19,1.21.66,0,1.18-.54,1.19-1.24,0-.55,0-1.1,0-1.65Z" />
  </svg>


</template>


<script>
export default {
  props: ['name']
}
</script>


<style>
.cls-2t {
  fill: #fff;
}

.cls-23 {
  fill: #fbca04;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 1.05px;
}

.cls-33 {
  fill: #222220;
}
</style>