<template>
  <v-container fluid class="my-12">
    <v-row justify="center">
      <v-col
        cols="10"
        md="10"
        sm="10"
        lg="10"
        class="blue_text--text d-flex justify-space-between flex-wrap gap-max-768px"
      >
        <div class="first-column-prefooter">
          <h2 class="text-uppercase font-weight-black font-main-title">
            Instituto Columbia
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              style="padding: 30px 0px !important; margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium content-common-component"
              >
                <i> RM No. 010-2019 MINEDU</i>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <h2 class="text-uppercase font-weight-bold font-second-title">
            CARRERAS
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              style="padding: 30px 0px !important; margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium content-common-component black--text"
                @click="$router.push('/hoteleriainternacional')"
                role="button"
              >
                Administración de Servicios de Hostelería y Restaurantes <br> con mención en Gerencia de la Hospitalidad Internacional
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component black--text"
                @click="$router.push('/altacocina')"
                role="button"
              >
                Cocina
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <h2 class="text-uppercase font-weight-bold font-second-title">
            Programas de Formación Continua
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              class="pt-8 pb-0"
              style="margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component"
                @click="$router.push('/pasteleriapanaderia')"
                role="button"
              >
                Panadería y Pastelería
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component"
                @click="$router.push('/bartender')"
                role="button"
              >
                Bartender
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component"
                @click="
                  $router.push(
                    '/columbiaonline#software-informatico-para-hoteles-y-restaurantes'
                  )"
                role="button"
              >
                Software informático para Hoteles y Restaurantes
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component"
                @click="$router.push('/columbiaonline#counter')"
                role="button"
              >
                Counter
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium mt-3 content-common-component"
                @click="
                  $router.push(
                    '/columbiaonline#atencion'
                  )"
                role="button"
              >
                Servicio y atención de viajeros a bordo
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium content-common-component"
                @click="$router.push('/columbiaonline#cabin-crew')"
                role="button"
              >
                Servicio y hospitalidad en vuelos comerciales
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div class="second-column-prefooter">
          <h2 class="text-uppercase font-weight-black font-main-title">
            CETPRO COLUMBIA
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              style="padding: 30px 0px !important; margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium content-common-component"
              >
                <i> RDR No. 05741-2009 DRELM - Lince</i>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium content-common-component"
              >
                <i> RDR No. 111885-2014 - Los Olivos</i>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <h2 class="text-uppercase font-weight-bold font-second-title">
            ESPECIALIDAD
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              style="padding: 30px 0px !important; margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium content-common-component black--text"
                @click="$router.push('/aviacioncomercial')"
                role="button"
              >
                Aviación Comercial
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div class="third-column-prefooter">
          <h2 class="text-uppercase font-weight-black font-main-title">
            Sedes
          </h2>
          <v-list-item three-line class="delete_padding font-weight-black">
            <v-list-item-content
              style="padding: 30px 0px !important; margin-top: -16px !important"
            >
              <v-list-item-subtitle
                class="font-weight-medium content-common-componentt"
              >
              <strong><span style="font-size: 1.5rem !important">INSTITUTO</span></strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium content-common-component"
              >
              <strong
                  ><span style="font-size: 105% !important"
                    >SAN ISIDRO</span
                  ></strong
                >
                <br />
                Av. Javier Prado Este 515
                
              </v-list-item-subtitle>
              
              <v-list-item-subtitle
                class="font-weight-medium mt-5 content-common-componentt">
              <strong><span style="font-size: 1.5rem !important">CETPRO</span></strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="font-weight-medium content-common-component">
                <strong><span style="font-size: 105% !important">LINCE</span></strong>
                <br />
                Av. Paseo de la República 2232
              </v-list-item-subtitle>

              <v-list-item-subtitle
                class="font-weight-medium mt-5 content-common-componentt">
              <strong><span style="font-size: 1.5rem !important">CETPRO</span></strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="font-weight-medium content-common-component">
                <strong><span style="font-size: 105% !important">LOS OLIVOS</span></strong>
                <br />
                Av. Angélica Gamarra 160-174
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Pre_footer",

  data: () => ({}),
});
</script>
<style>
.first-column-prefooter {
  width: 40%;
}
.second-column-prefooter {
  width: 30%;
}
.third-column-prefooter {
  width: 25%;
}
.font-main-title {
  font-size: 1.56rem;
}
.font-second-title {
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .first-column-prefooter {
    width: 100%;
  }
  .second-column-prefooter {
    width: 100%;
  }
  .third-column-prefooter {
    width: 100%;
  }
  .gap-max-768px {
    gap: 1.5rem;
  }
  .font-main-title {
    font-size: 1.2rem;
  }
  .font-second-title {
    font-size: 1rem;
  }
}
</style>

<style scoped>
.delete_padding {
  padding: 0 !important;
}
</style>
