<template>
  <v-footer dark padless>
    <v-container fluid class="footer_color white--text text-center">
      <v-row justify="center" class="mt-7 d-none d-md-flex">
        <v-btn
          :style="{ 'font-size': font }"
          v-for="(link, indexLink) in footer_links"
          @click="$router.push(link.route)"
          v-bind:class="
            link.name != 'Portal de transparencia' ? 'btn_foot_border' : ''
          "
          :key="indexLink"
          color="white"
          text
          class="my-2"
        >
          {{ link.name }}
        </v-btn>
      </v-row>

      <v-row class="mt-8" justify="center" align="center">
        <v-col cols="10" sm="5" md="4" lg="4">
          <img
            class="img_footer"
            src="@/assets/pages/footer/columbia-logo-footer.svg"
            alt="Logo"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="5"
          lg="6"
          class="text-md-left text-sm-center"
        >
          <v-row no-glutters>
            <v-col cols="12" lg="6" class="d-flex justify-center">
              <h1 :style="{ 'font-size': '1.53rem' }">01 500 7000</h1>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              lg="6"
              class="d-flex justify-center">
              <v-card-text :class="mt3" class="pa-0 d-flex justify-center">
                <section class="d-flex justify-center">
                  <a
                    href="https://www.facebook.com/columbiacentrodeestudios"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none"
                  >
                    <v-btn class="white--text ml-5 mt-2" icon>
                      <img
                        class="img_icons_footer"
                        src="@/assets/icons/Footer_icons/Recurso50.svg"
                        alt=""
                      />
                    </v-btn>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/columbiaperu/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none"
                    ><v-btn class="white--text ml-5 mt-2" icon>
                      <img
                        class="img_icons_footer"
                        src="@/assets/icons/Footer_icons/Recurso47.svg"
                        alt=""
                      /> </v-btn
                  ></a>
                  <a
                    href="https://www.youtube.com/@corporacioncolumbia"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none"
                    ><v-btn class="white--text ml-5 mt-2" icon>
                      <img
                        class="img_icons_footer"
                        src="@/assets/icons/Footer_icons/Recurso48.svg"
                        alt=""
                      /> </v-btn
                  ></a>
                  <a
                    href="https://www.instagram.com/columbia_peru/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none"
                  >
                    <v-btn class="white--text ml-5 mt-2" icon>
                      <img
                        class="img_icons_footer"
                        src="@/assets/icons/Footer_icons/Recurso51.svg"
                        alt=""
                      />
                    </v-btn>
                  </a>
                  <a
                    href="https://www.tiktok.com/@columbiaperu"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none"
                  >
                    <v-btn class="white--text ml-5 mt-2" icon>
                      <img
                        class="img_icons_footer"
                        src="@/assets/icons/Footer_icons/Recurso49.svg"
                        alt=""
                      />
                    </v-btn>
                  </a>
                </section>
              </v-card-text>
            </v-col>
          </v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center">
              <h1 :style="{ 'font-size': '1.53rem' }">informes@columbia.edu.pe</h1>
            </v-col>
          <v-row no-glutters>
            <v-col cols="12" lg="6" class="d-flex justify-center">
              <v-btn
                v-bind:style="{ width: wd, height: mxh }"
                style="border-radius: 10px !important"
                outlined
                color="white"
              >
                <icon
                  :style="{ 'min-width': min_width_img_btn }"
                  class="svg_icon_pay"
                  name="pay"
                ></icon>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="6" class="d-flex justify-center">
              <a
                href="https://reclamaciones.columbia.edu.pe/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none"
              >
                <v-btn
                  v-bind:style="{ width: wd, height: mxh }"
                  style="border-radius: 10px !important"
                  outlined
                  color="white"
                >
                  <icon class="svg_icon_book btn_foot" name="book"></icon>
                  <div>Libro de reclamaciones</div>
                </v-btn>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-divider class="test"></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="white--text mt-8">
        <v-col cols="10" lg="10">
          <v-row>
            <v-col cols="12" md="6" sm="12" lg="6">
              <p
                :style="{ 'font-size': font_copy }"
                class="text_footer_font text-center"
              >
                Copyright 2022 TODOS LOS DERECHOS RESERVADOS A CORPORACIÓN EDUCA
                S.A.C. - RUC: 20602618995
              </p>
            </v-col>
            <v-col cols="4" md="2" sm="4" lg="2">
              <a
                href="https://www.columbia.edu.pe/terminos-y-condiciones/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none white--text"
              >
                <p
                  :style="{ 'font-size': font_copy }"
                  class="text_footer_font text-center"
                >
                  Términos y Condiciones
                </p>
              </a>
            </v-col>
            <v-col cols="4" md="2" sm="4" lg="2">
              <a
                href="https://www.columbia.edu.pe/politica-de-proteccion-de-datos/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none white--text"
              >
                <p
                  :style="{ 'font-size': font_copy }"
                  class="text_footer_font text-center"
                >
                  Política de Privacidad
                </p>
              </a>
            </v-col>
            <v-col cols="4" md="2" sm="4" lg="2">
              <a
                href="https://factelec.columbia.edu.pe/e-facturacion"
                target="_blank"
                rel="noopener noreferrer"
                class="text-decoration-none white--text"
              >
                <p :style="{ 'font-size': font_copy }" class="text-center">
                  Comprobantes de pago
                </p>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<style scoped>
.img_icons_footer {
  width: 30px;
  height: 30px;
}
.img_footer {
  width: 250px;
  height: 110px;
}
@media screen and (min-width: 1651px) {
  .img_footer {
    width: 280px;
    height: 130px;
  }
}
</style>

<script lang="ts">
import Vue from "vue";
import Icon from "@/assets/icons/Icon.vue";

export default Vue.extend({
  components: {
    Icon,
  },
  name: "Footer",
  data: () => ({
    font: "",
    font_copy: "",
    size_button: "",
    img_footer: "",
    ft: "",
    wd: "",
    mxh: "",
    min_width_img_btn: "",
    size_icon: "30",
    mt3: "",
    padding: "",

    icons: [
      "mdi-facebook",
      "mdi-linkedin",
      "mdi-youtube",
      "mdi-instagram",
      "mdi-twitter",
    ],
    footer_links: [
      {
        name: "Inicio",
        route: "/home",
      },
      {
        name: "Sobre Columbia",
        route: "/about1",
      },
      {
        name: "Oferta Educativa",
        route: "/educative_offer",
      },
      {
        name: "Partners",
        route: "/partners",
      },
      {
        name: "Profesionales Columbia",
        route: "/professionals",
      },
      {
        name: "Portal del Alumno",
        route: "/portal",
      },
      // {
      //   name: "Blog",
      //   route: "",
      // },
      {
        name: "Portal de transparencia",
        route: "/portal-de-transparencia",
      },
    ],
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      if (window.innerWidth > 1650) {
        this.font = "16px";
        this.font_copy = "11px";
        this.ft = "3rem";
        this.img_footer = "img_footer";
        this.wd = "270px !important";
        this.mxh = "50px !important";
        this.min_width_img_btn = "160px";
        this.size_icon = "50";
        this.mt3 = "mt-3";
        this.padding = "pa-12";
      } else {
        this.font = "12px";
        this.font_copy = "11px";
        this.ft = "";
        //this.img_footer = "img_footer2"
        this.wd = "";
        this.mxh = "";
        this.min_width_img_btn = "";
        this.size_icon = "30";
        this.mt3 = "";
        this.padding = "";
      }
    },
  },
});
</script>

<style>
.footer_color {
  background: rgba(42, 45, 206, 0.774);
  background: linear-gradient(0deg, hsla(256, 95%, 62%, 0.582), #0059f3 98%);
}

.test {
  border-color: rgb(255, 255, 255) !important;
  height: 100%;
}

.v-card {
  width: 100%;
}

.svg_icon_book {
  width: 30px;
  height: 20px;
  margin-right: 10px;
  fill: white;
}

.svg_icon_pay {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  fill: white;
}

.text_footer_font {
  border-right: solid 1px;
  color: white;
}

.btn_foot_border {
  border-right: 1px solid !important;
  border-radius: unset !important;
}

.btn_foot {
  font-size: 54px !important;
}
</style>
