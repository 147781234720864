import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.config.productionTip = false;

// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: 'G-DVL6Z7Q3ZV',
//   router
// });

// Vue.use(VueAnalytics, {
//   id: 'AW-639789420',
//   router
// });

const gtmOptions = {
  id: "GTM-NCJJR9GM", // Reemplaza con tu ID de GTM
  defer: false, // Opciones de configuración (puedes dejar los valores por defecto si quieres)
  compatibility: false,
};

Vue.use(VueGtm, gtmOptions);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
