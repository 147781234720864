import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    redirect: { name: "home" },
    component: HomeView,
  },
  {
    path: "/about1",
    name: "about1",
    component: () => import("@/views/AboutView_1.vue"),
  },
  {
    path: "/about2",
    name: "about2",
    component: () => import("@/views/AboutView_2.vue"),
  },
  {
    path: "/about3",
    name: "about3",
    component: () => import("@/views/AboutView_3.vue"),
  },
  {
    path: "/educative_offer",
    name: "educative_offer",
    component: () => import("@/views/EducativeOffertView_1.vue"),
  },
  {
    path: "/hoteleriainternacional",
    name: "hoteleriainternacional",
    component: () => import("@/views/EducativeOffertView_2.vue"),
  },
  {
    path: "/altacocina",
    name: "altacocina",
    component: () => import("@/views/EducativeOffertView_3.vue"),
  },
  {
    path: "/bartender",
    name: "bartender",
    component: () => import("@/views/EducativeOffertView_4.vue"),
  },
  {
    path: "/pasteleriapanaderia",
    name: "pasteleriapanaderia",
    component: () => import("@/views/EducativeOffertView_5.vue"),
  },
  {
    path: "/columbiaonline",
    name: "columbiaonline",
    component: () => import("@/views/EducativeOffertView_6.vue"),
  },
  {
    path: "/educative_offer7",
    name: "educative_offer7",
    component: () => import("@/views/EducativeOffertView_7.vue"),
  },
  {
    path: "/aviacioncomercial",
    name: "aviacioncomercial",
    component: () => import("@/views/EducativeOffertView_8.vue"),
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("@/views/PartnersView.vue"),
  },
  {
    path: "/professionals",
    name: "professionals",
    component: () => import("@/views/ProfessionalsView.vue"),
  },
  {
    path: "/portal",
    name: "portal",
    component: () => import("@/views/PortalView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("@/views/BlogView.vue"),
  },
  {
    path: "/portal-de-transparencia",
    name: "portal-de-transparencia",
    component: () => import("@/views/PortalDeTransparenciaView.vue"),
  },
  {
    path: "/registrate",
    name: "registrate",
    component: () => import("@/components/Form.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return new Promise((resolve) => {
      if (to.hash) {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 70 },
            behavior: "smooth",
          });
        }, 1000);
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  },
});

export default router;
