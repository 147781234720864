<template>
  <v-app>
    <Navigation_bar />
    <div>
      <v-main class="padding-top-main-content">
        <router-view />
      </v-main>
    </div>
    <Pre_footer />
    <Footer />
    <Whatsapp></Whatsapp>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import Navigation_bar from "./components/Navigation_bar.vue";
import Pre_footer from "./components/Pre_footer.vue";
import Footer from "./components/Footer.vue";
import "@/assets/css/main.css";
const Whatsapp = () => import("@/components/Home_Sections/Whatsapp.vue");

export default Vue.extend({
  name: "App",
  components: {
    Navigation_bar,
    Footer,
    Pre_footer,
    Whatsapp,
  },
  data: () => ({
    //
  }),
});
</script>
<style>
.padding-top-main-content {
  padding-top: 80px !important;
}

@media screen and (min-width: 960px) {
  .padding-top-main-content {
    padding-top: 88px !important;
  }
}
</style>
