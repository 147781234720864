<head>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-DVL6Z7Q3ZV"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-DVL6Z7Q3ZV');
</script>
</head>

<template>
  
  <HomePage />

</template>

<script lang="ts">
import Vue from "vue";
const HomePage = () => import("@/components/HomePage.vue")


export default Vue.extend({
  name: "Home",

  components: {
    HomePage,
    
  },
});
</script>
